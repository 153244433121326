import React, { Fragment } from 'react';
import { Table, Icon, Loader } from 'semantic-ui-react';
import IProposal from './IProposal'
import { useAuth0 } from '@auth0/auth0-react';



export const ProposalRow = ({ id, createdAt, client, onDelete, tenant, email, onView, loading, onEdit }: IProposal) => {
    const { user } = useAuth0();
    const date = new Date(createdAt);
    const uuid = tenant.slice(0, 2).toUpperCase() + "-" + id + "-" + date.getFullYear();
    const canSee = user.email.split("@")[1].startsWith("phygitall");
    return (
        <Table.Row>
            <Table.Cell>{uuid}</Table.Cell>
            {canSee && <Table.Cell>{tenant}</Table.Cell>}
            {canSee && <Table.Cell>{email?.split("@")[0]}</Table.Cell>}
            <Table.Cell>{client}</Table.Cell>
            <Table.Cell>{date.toLocaleString()}</Table.Cell>

            <Table.Cell>
                {loading ?
                    <Loader size="small" active inline="centered" /> :
                    <Fragment>
                        <Icon title="Visualizar" id={id} name="file" link onClick={onView} />
                        <Icon  title="Editar" id={id} name="edit" link color="blue" onClick={onEdit} />
                        <Icon title="Excluir" id={id} uuid={uuid} name="trash" link color="red" onClick={onDelete} />
                    </Fragment>}
                </Table.Cell>
        </Table.Row>
    )
}
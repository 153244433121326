import React from "react";
import {
  Container,
  //Dropdown,
  Image,
  Menu,
} from "semantic-ui-react";
import LoginButton from "./LoginButton";
import { Link } from "react-router-dom";

export const Navbar = () => (
  <Menu fixed="top" inverted>
    <Container>
      <Menu.Item header>
        <Image
          as={Link}
          to="/"
          size="small"
          src="/logo.png"
          style={{ marginRight: "1.5em" }}
        />
      </Menu.Item>
      <Menu.Item as={Link} to="/leads">
        Leads
      </Menu.Item>
      {/*<Dropdown item simple text='Ferramentas'>
        <Menu.Item as='a'>Propostas</Menu.Item>

          <Dropdown.Menu>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Header Item</Dropdown.Header>
            <Dropdown.Item>
              <i className='dropdown icon' />
              <span className='text'>Submenu</span>
              <Dropdown.Menu>
                <Dropdown.Item>List Item</Dropdown.Item>
                <Dropdown.Item>List Item</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
    </Container>
    <Menu.Item position="right">
      <LoginButton />
    </Menu.Item>
  </Menu>
);

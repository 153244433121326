import React from 'react';
import { Container, Header, Table, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ProposalBody } from './ProposalBody';
import { useAuth0 } from '@auth0/auth0-react';

export const ListProposal = () => {
    const { user } = useAuth0();
    const canSee = user.email.split("@")[1].startsWith("phygitall");
    return (
    <Container text style={{ marginTop: '7em', minHeight: '360px' }}>
        <Header as='h1'>Meus Orçamentos</Header>
        <Grid>
            <Grid.Column floated="right">
            <Button as={Link} floated="right" primary content="Criar Orçamento" to="/proposal/opportunity"/>
            </Grid.Column>
        </Grid>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Orçamento</Table.HeaderCell>
                    {canSee && <Table.HeaderCell>Canal</Table.HeaderCell>}
                    {canSee && <Table.HeaderCell>Usuário</Table.HeaderCell>}
                    <Table.HeaderCell>Cliente</Table.HeaderCell>
                    <Table.HeaderCell>Criado em</Table.HeaderCell>
                    <Table.HeaderCell>Ações</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <ProposalBody canSee={canSee}/>
        </Table>
    </Container>
)}


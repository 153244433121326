import React from 'react';
import { Step, Grid } from 'semantic-ui-react';

export const ProposalStep = ({ current }: { current: string }) => {
    const isCompleted = [current !== "", current === "pricing" || current === "review"];
    const isActive = [current === "", current === "solution", current === "pricing"];
    return (
        <Grid container verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    <Step.Group ordered  widths={3}>
                        <Step completed={isCompleted[0]} active={isActive[0]}>
                            <Step.Content>
                                <Step.Title>Oportunidade</Step.Title>
                            </Step.Content>
                        </Step>

                        <Step completed={isCompleted[1]} active={isActive[1]}>
                            <Step.Content>
                                <Step.Title>Solução</Step.Title>
                            </Step.Content>
                        </Step>

                        <Step completed={current === "review"} active={isActive[2]}>
                            <Step.Content>
                                <Step.Title>Preço</Step.Title>
                            </Step.Content>
                        </Step>
                    </Step.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
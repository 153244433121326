import React from 'react';
import { Message, Container } from 'semantic-ui-react';


export const LoginWarning = () => (
    <Container text style={{ marginTop: '7em', minHeight: 235 }}>
        <Message warning>
            <Message.Header>Você não está logado</Message.Header>
            <p>Você será redirecionado para a página de login.</p>
        </Message>
    </Container>
)
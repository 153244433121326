import React, { useEffect, useState } from 'react';
import { fetchProposals, deleteProposal, viewProposal } from '../../services/proposal'
import { useAuth0 } from '@auth0/auth0-react';
import { Table, Confirm, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { ProposalRow } from './ProposalRow';
import updateAction from "../../utils/updateAction";
import IProposal from './IProposal';
import { fetchOneProposal } from '../../services/proposal'
import { useStateMachine } from 'little-state-machine';
import { useHistory } from 'react-router-dom';


export const ProposalBody = ({ canSee }: { canSee: boolean }) => {
    const { user } = useAuth0()
    const { action } = useStateMachine(updateAction);
    const [proposals, setProposals] = useState<IProposal[]>([])
    const [confirmScreen, openConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mainLoading, setMainLoading] = useState(true);
    const [currentId, setId] = useState(null)
    const [uuid, changeUuid] = useState(null)
    const { addToast } = useToasts();
    const history = useHistory();



    useEffect(() => {
        (async () => {
            const { res } = await fetchProposals(user.email);
            setProposals(res)
            setMainLoading(false)
        }
        )()
    }, [user])

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, props: any) => {
        setId(props.id)
        changeUuid(props.uuid)
        openConfirm(true)
    }
    const handleView = (e: React.MouseEvent<HTMLButtonElement>, props: any) => {
        setId(props.id)
        setLoading(true)
        viewProposal(props.id)
            .then(html => {
                const blob = new Blob([html.res], { type: 'text/html' });
                const blobUrl = URL.createObjectURL(blob)
                setLoading(false)
                window.open(blobUrl)
            })
            .catch(err => {
                console.error(err);
                setLoading(false)
                addToast('Não é possível vizualizar esse orçamento nesse momento.', { appearance: 'error' })
            })
    }
    const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, props: any) => {
        setId(props.id)
        setLoading(true)
        fetchOneProposal(props.id)
            .then(({ res })=> {
                action(res.sent_form)
                setLoading(false)
                history.push(`/proposal/opportunity?id=${props.id}`)
            })
            .catch((err) => {
            setLoading(false)
            console.error(err)
            })
    }
    const confirmedDelete = async () => {
        openConfirm(false)
        setLoading(true)
        deleteProposal(currentId)
            .then(() => {
                setLoading(false)
                const leftProposals = proposals.filter((p: IProposal) => p?.id !== currentId)
                setProposals(leftProposals)
                addToast('Orçamento excluído com sucesso!', { appearance: 'success' })
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
                addToast('Ocorreu um problema na exclusão desse orçamento.', { appearance: 'error' })
            })
    }
    const onCancel = () => { openConfirm(false) }
    return (
        <Table.Body>
            {proposals.length > 0 ?
                proposals.map((proposal: IProposal) =>
                    <ProposalRow
                        {...proposal}
                        key={proposal.id}
                        onDelete={handleDelete}
                        onView={handleView}
                        onEdit={handleEdit}
                        loading={currentId === proposal.id ? loading : false}
                    />)
                : <Table.Row >
                    <Table.Cell colSpan={canSee ? "6" : "4"} textAlign="center">
                        {mainLoading ? <Loader active inline="centered"/> : "Você ainda não criou um orçamento."}
                </Table.Cell>
                </Table.Row>}
            <Confirm
                open={confirmScreen}
                content={`Deseja excluir permanentemente o orçamento ${uuid}`}
                confirmButton="Excluir"
                cancelButton='Cancelar'
                size='tiny'
                onConfirm={confirmedDelete}
                onCancel={onCancel}
            />
        </Table.Body>
    )
}
import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
//import { Loader } from 'semantic-ui-react';

const PrivateRoute = ({ component, ...args }: React.PropsWithChildren<any>) => (
  <Route
    component={withAuthenticationRequired(component)}
    {...args}
  />
);

export default PrivateRoute;
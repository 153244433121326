import React from 'react'
import {
    Container,
    Header,
} from 'semantic-ui-react';
import Form from '../components/Form/PricingForm'
import { ProposalStep } from '../components';

export const Pricing = () => {
    return (
        <Container text style={{ marginTop: '7em' }}>
            <ProposalStep current="pricing" />
            <Header as='h1'>Precificação</Header>
            <Form />
        </Container>
    )
}
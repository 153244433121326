import React from 'react'
import {
    Container,
    Header,
} from 'semantic-ui-react';
import ProposalForm from '../components/Form/ProposalForm'
import { ProposalStep } from '../components';

export const Content = () => {
    return (
        <Container text style={{ marginTop: '7em' }}>
            <ProposalStep current="solution" />
            <Header as='h1'>Solução</Header>
            <ProposalForm />
        </Container>
    )
}
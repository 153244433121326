import React from "react";
import "./PreviewTable.css";

interface PreviewTableProps {
  leads: any[];
}

const PreviewTable = ({ leads }: PreviewTableProps) => {
  if (leads.length === 0) return null;
  return (
    <table className="my-table">
      <thead>
        <tr>
          {Object.keys(leads[0]).map((key) => (
            <th key={key}>{key.toUpperCase()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {leads.map((row, index) => (
          <tr key={index}>
            {Object.keys(row).map((key) => (
              <td key={key}>{row[key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PreviewTable;

import React, { useEffect, useState, CSSProperties } from 'react'
import {
    Container,
    Header,
    Button
} from 'semantic-ui-react';
import { ProposalStep } from '../../components';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../../utils/updateAction';
import { createProposal, editProposal } from '../../services/proposal';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import queryString from 'query-string'
import { ReviewSection } from './ReviewSection'
import { useToasts } from 'react-toast-notifications';

export const Review = () => {
    const { state } = useStateMachine(updateAction);
    const [loading, setLoading] = useState<boolean>(false);
    const [url, setUrl] = useState<string>("");
    const { user } = useAuth0();
    const location = useLocation();
    const history = useHistory();
    const { addToast } = useToasts();
    const returnPath = {
        pathname: "/proposal/pricing",
        search: location.search,
    }
    useEffect(()=> () => URL.revokeObjectURL(url), [url])

    const onSubmit = async () => {
        setLoading(true)
        try{
            const html = await editOrCreateProposal()
            const blob = new Blob([html.res], {type: 'text/html'});
            const blobUrl = URL.createObjectURL(blob)
            console.log(blobUrl)
            setUrl(blobUrl)
            setLoading(false)
            addToast('Criado com sucesso!', { appearance: 'success' })
            window.open(blobUrl)
            history.push("/proposal");
        } catch (err){
            setLoading(false)
            addToast('Ocorreu um erro, tente mais tarde.', { appearance: 'error' })
            console.error(err)
        }   
    };

    const editOrCreateProposal = async () => {
        if(location.search) {
            const { id } = queryString.parse(location.search)
          if(id && !Array.isArray(id)) {
            const html = await editProposal(id, state.data)
            return html;
          }
        }
        const tenant = user["http://plataforma.phygitall.com.br/empresa"];
        const body = {...state.data, user: user.email, tenant}
        const html = await createProposal(body);
        return html;
    }
    
    return (
        <Container text style={{ marginTop: '7em' }}>
            <ProposalStep current="review" />
            <Header as='h1'>Resumo do orçamento</Header>
            <Header as="h3">Confira se os dados do seu orçamento estão corretos.</Header>
            <ReviewSection formValues={state.data} />
            {/* <pre>{JSON.stringify(state.data, null, 2)}</pre> */}
            <div style={buttomStyle}>
                <Button as={ Link } secondary icon='left arrow' content='Retornar' to={returnPath}/>
                <Button type='submit' primary loading={loading} onClick={onSubmit}>{location.search ? "Editar Orçamento" : "Gerar Orçamento"}</Button>
            </div>
        </Container>
    )
}


const buttomStyle: CSSProperties = {
    margin: 'auto',
    width: '64%',
    padding: 10
}
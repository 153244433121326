import axios from "axios";

export const createProposal = async (data: object) => {
  const response = await axios.post("https://backend.phygitall.com/v0/proposal", {
    headers: {
      "Content-Type": "application/json",

    },
    body: data
  });
  const res = await response.data;

  if (response.status !== 201) {
    throw new Error(res.error);
  }

  return {
    status: response.status,
    res
  };
};

export const fetchProposals = async (email: string) => {
  const response = await axios.get(`https://backend.phygitall.com/v0/proposal?email=${email}`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
  const res = await response.data;

  if (response.status !== 200) {
    throw new Error(res.error);
  }

  return {
    status: response.status,
    res
  };
};

export const editProposal = async (id: string, data: object) => {
  const response = await axios.put("https://backend.phygitall.com/v0/proposal/"+ id, {
    headers: {
      "Content-Type": "application/json",

    },
    body: data
  });
  const res = await response.data;

  if (response.status !== 200) {
    throw new Error(res.error);
  }

  return {
    status: response.status,
    res
  };
};


export const fetchOneProposal = async (id: string) => {
  const response = await axios.get(`https://backend.phygitall.com/v0/proposal/${id}`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
  const res = await response.data;

  if (response.status !== 200) {
    throw new Error(res.error);
  }

  return {
    status: response.status,
    res
  };
};


export const deleteProposal = async (id: string|null) => {
  const response = await axios.delete(`https://backend.phygitall.com/v0/proposal/${id}`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
  const res = await response.data;

  if (response.status !== 200) {
    throw new Error(res.error);
  }

  return {
    status: response.status,
    res
  };
};

export const viewProposal = async (id: string) => {
  const response = await axios.get(`https://backend.phygitall.com/v0/proposal/${id}/view`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
  const res = await response.data;

  if (response.status !== 200) {
    throw new Error(res.error);
  }

  return {
    status: response.status,
    res
  };
};


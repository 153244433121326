import React, { CSSProperties } from 'react';
import { Checkbox, Grid } from 'semantic-ui-react';

type values = {
    client: string,
    state: string,
    contact: string,
    contact_email: string,
    demanding_sector: string,
    maturity_level: string,
    budget: string,
    wireless_infra: string,
    commercial_stage: string,
    expectation: string,
    model: string,
    environment: string,
    target: string,
    use: string,
    product: string,
    quantity: string,
    quantity_spare: string,
    potential_quantity: string,
    expenditure: string,
    paymentMethod: string,
    contract: string,
    format: string,
    attachment_institucional: boolean,
    attachment_solution: boolean,
    attachment_tech: boolean,
    spect_name: string,
    hh_business: number,
    scope_description: string,
    hh_software: number,
    hh_hardware: number
}

type ReviewSectionProps = {
    formValues: values
}

interface Ihash {
    key: keyof values;
    label: string;
}

export const ReviewSection = ({ formValues }: ReviewSectionProps) => (
    <Grid padded>
        {hashmap.map((({ label, key }) =>
            typeof formValues[key] !== "undefined" &&
            <Grid.Row style={noPadding} key={key}>
                <Grid.Column width={6} textAlign='right'>
                    <strong>{label}:</strong>
                </Grid.Column>
                <Grid.Column width={4}>
                    {formValues[key]}
                </Grid.Column>
            </Grid.Row>)
        )}
        <Grid.Row columns={1}>
            <Grid.Column width={6} textAlign='right'>
                <strong>Documentos em anexo:</strong>
            </Grid.Column>
            <Grid.Column width={7}>
                <Grid.Row style={noPadding}>
                    <Checkbox
                        label="Apresentação Institucional Phygitall"
                        checked={formValues.attachment_institucional}
                        readOnly
                    />
                </Grid.Row>
                <Grid.Row style={noPadding}>
                    <Checkbox
                        label="Apresentação da Solução"
                        checked={formValues.attachment_solution}
                        readOnly />
                </Grid.Row>
                <Grid.Row style={noPaddingTop}>
                    <Checkbox
                        label="Documentos Técnicos"
                        checked={formValues.attachment_tech}
                        readOnly />
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
)


const hashmap: Array<Ihash> = [
    { label: "Cliente", key: "client" },
    { label: "Aplicação", key: "model" },
    { label: "Ambiente", key: "environment" },
    { label: "Alvo", key: "target" },
    { label: "Tamanho", key: "use" },
    { label: "Produto", key: "product" },
    { label: "Quantidade de Produto Inicial", key: "quantity" },
    { label: "Quantidade Spare Part", key: "quantity_spare" },
    { label: "Quantidade Potencial", key: "potential_quantity" },
    { label: "Modalidade", key: "expenditure" },
    { label: "Forma de Pagamento", key: "paymentMethod" },
    { label: "Contrato", key: "contract" },
    { label: "Formato", key: "format" },
    { label: "Especificação", key: "spect_name"},
    {label: "HH Analista de Negócios", key: "hh_business"},
    {label: "Descrição do escopo", key: "scope_description"},
    {label: "HH Desenvolvimento Software", key: "hh_software"},
    {label: "HH Desenvolvimento Hardware", key: "hh_hardware"}
]

const noPadding: CSSProperties = {
    paddingTop: "0rem",
    paddingBottom: "0rem"
}

const noPaddingTop: CSSProperties = {
    paddingTop: "0rem"
}
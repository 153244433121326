import React, { Fragment, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Form, Header, Divider, Button, Icon } from 'semantic-ui-react';

export const Phases = ({ control, state }: any) => {
    const [num, setNum] = useState(1);
    const addMore = () => { setNum(num + 1) }
    const closeItem = () => { setNum(num - 1) }
    useEffect(() => {
        if (state.data.phase) {
            const len = Object.values(state.data.phase).length / 2;
            if(len > 0)
                setNum(len)
        }
    }, [state])
    return (
        <Fragment>
            {Array(num).fill(0).map((el, i) => {
                const currentPhase = i + 1;
                return (
                    <Fragment key={currentPhase}>
                        <Header as="h4">Fase {currentPhase}</Header>
                        <Divider />
                        <Form.Group>
                            <Controller
                                as={<Form.Input
                                    placeholder="Descrição"
                                />}
                                name={"phase.description_" + currentPhase}
                                control={control}
                                defaultValue={state.data["phase.description_" + currentPhase]}
                            />
                            <Controller
                                as={<Form.Input
                                    placeholder="Tempo"
                                    type="number"
                                />}
                                name={"phase.hh_" + currentPhase}
                                control={control}
                                defaultValue={state.data["phase.hh_" + currentPhase]}
                            />
                            {(i + 1) === num &&
                                <Fragment>
                                    <Button type="button" icon onClick={addMore}><Icon name="add" /></Button>
                                    {i !== 0 && <Button type="button" color="red" icon onClick={closeItem}><Icon name="close" /></Button>}
                                </Fragment>}
                        </Form.Group>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}
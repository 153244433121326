import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'semantic-ui-react'

const LoginButton = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return isAuthenticated ?
    <Button onClick={() => logout({ returnTo: window.location.origin })} inverted floated="right"> Sair</Button> :
    <Button onClick={() => loginWithRedirect()} inverted>Entrar</Button>
};

export default LoginButton;
import React, { useState } from "react";
import PreviewTable from "../components/PreviewTable";
import { fetchSales } from "../services/sales";
import { Input, Button } from "semantic-ui-react";

export const Leads = () => {
  const [query, setQuery] = useState("AND(Segurança, Ambev)");
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(ev.target.value);
  };
  const handlePreview = async () => {
    try {
      setLoading(true);
      const response = await fetchSales({
        query,
        preview: true,
      });
      setLeads(response.leads);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  const handleDownloadCsv = async () => {
    try {
      setDownload(true);
      const response = await fetchSales({
        query,
        preview: false,
        pages: 10,
      });
      const headers = Object.keys(response.leads[0]).join(",");
      const csvData =
        "data:text/csv;charset=utf-8," +
        headers +
        response.leads
          .map((lead: any) => Object.values(lead).join(","))
          .join("\n");

      const encodedUri = encodeURI(csvData);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "leads.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false);
    } catch (err) {
      setDownload(false);
      console.error(err);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.search}>
        <Input onChange={handleChange} value={query} />
        <Button onClick={handlePreview} loading={loading}>
          Preview
        </Button>
        <Button onClick={handleDownloadCsv} loading={download}>
          {" "}
          Download csv{" "}
        </Button>
      </div>

      <PreviewTable leads={leads} />
    </div>
  );
};

const styles = {
  container: {
    height: "76vh",
    padding: "5rem",
  },
  search: {
    padding: "1rem",
  },
};

import React, { Fragment, useEffect, useState } from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Controller } from 'react-hook-form'
import { Hint } from '..'

const tiMaturity = [
    {
        value: "Muita maturidade",
        name: "Tem muito conhecimento sobre Rede LoRa",
        key:"t1"
    },
    {
        value: "Alguma maturidade",
        name: "Tem algum conhecimento sobre Rede LoRa",
        key:"t2"
    },
    {
        value: "Nenhuma maturidade",
        name: "Não tem nenhum conhecimento sobre Rede LoRa",
        key:"t3"
    },
    {
        value: "Não relacionado a LoRa",
        name: "O projeto não tem relação com Rede LoRa",
        key:"t4"
    }
];

const clientMaturity = [
    {
        value: "Urgente",
        name: "Considera este projeto urgente",
        key:"c1"
    }, 
    {
        value:"Prioridade",
        name: "Considera este projeto prioridade",
        key:"c2"
    },
    {
        value:"Experimental",
        name: "Considera este projeto experimental",
        key:"c3"
    },
    {
        value:"Experimental",
        name: "Considera este projeto pouco relevante",
        key:"c4"
    }
];
const inovationMaturity = [
    {
    value: "inovação",
    name: "Este projeto é uma demanda da inovação",
    key:"i1"
    },
    {
        value: "cliente",
        name: "Este projeto é uma demanda da area cliente",
        key:"i2"
    },
    {
        value: "diretoria",
        name: "Este projeto é uma demanda da diretoria Global (para todas as unidades)",
        key:"i3"
    }
];


interface EnumOption {
    value: string; 
    name: string;
    key: string;
}

interface EnumOptionItems extends Array<EnumOption>{}

export const Persona = ({ control, errors, state, watch, setValue, register }: any) => {
    const [maturity, setMaturity] = useState<EnumOptionItems>();
    const sector = watch('demanding_sector', 'TI');
    const comercialStage = watch('commercial_stage');

    useEffect(()=> {
        if(sector === "TI"){
            setMaturity(tiMaturity)
            setValue('maturity_level', 'Muita maturidade')
        } else if(sector === "cliente interno"){
            setMaturity(clientMaturity)
            setValue('maturity_level', 'Urgente')
        } else {
            setMaturity(inovationMaturity)
            setValue('maturity_level', 'inovação')
        }
    }, [sector, setValue])
    return (
    <Fragment>
        <Header as="h3">Maturidade do Cliente</Header>
        <Controller
            as={<Form.Field
                label={<Fragment><b>Setor demandante</b> <Hint text="Setor interno da empresa que está buscando a solução." /></Fragment>}
                error={errors.demanding_sector && { content: "Informe o contato", pointing: "below" }}
                control='select'>
                <option value='TI'>TI</option>
                <option value='cliente interno'>Cliente interno</option>
                <option value='inovação'>Inovação</option>
            </Form.Field>
                }
            name="demanding_sector"
            control={control}
            defaultValue="TI"
        />
        <Controller
            as={<Form.Field
                label={<Fragment><b>Grau de Maturidade</b> <Hint text="Qual o nível de maturidade e entendimento da tecnologia e da solução por parte do cliente?" /></Fragment>}
                error={errors.maturity_level && { content: "Informe o grau de maturidade", pointing: "below" }}
                control='select'>
                    {maturity && maturity.map((option: EnumOption) => <option key={option.key} value={option.value}>{option.name}</option>)}
            </Form.Field>
            }
            name="maturity_level"
            control={control}
        />
        <Controller
            as={
                <Form.Field
                    label={<Fragment><b>Orçamento</b> <Hint text="Verificar se existe ou não o orçamento disponível para o projeto." /></Fragment>}
                    control='select'>
                    <option value='sim'>Com Budget</option>
                    <option value='não'>Sem Budget</option>
                </Form.Field>
            }
            name="budget"
            defaultValue="sim"
            control={control}
        />
        <Controller
            as={
                <Form.Field
                    label={<Fragment><b>Infraestrutura de Rede sem Fio</b> <Hint text="Qual o nível de cobertura de rede sem fio na empresa?" /></Fragment>}
                    control='select'>
                    <option value='total'>Cobertura total</option>
                    <option value='parcial'>Cobertura parcial</option>
                    <option value='minima'>Cobertura mínima</option>
                    <option value='não existente'>Não existe cobertura de rede</option>

                </Form.Field>
            }
            name="wireless_infra"
            defaultValue="total"
            control={control}
        />
        <Controller
            as={
                <Form.Field
                    label={<Fragment><b>Estágio Comercial</b> {/* <Hint text="A definir" /> */}</Fragment>}
                    control='select'>
                    <option value='ideia'>Ideia</option>
                    <option value='demo'>Demo</option>
                    <option value='poc'>POC</option>
                    <option value='rfi'>RFI</option>
                    <option value='rfp'>RFP</option>
                    <option value='lpu'>LPU</option>
                </Form.Field>
            }
            name="commercial_stage"
            defaultValue={state.data.commercial_stage || "ideia"}
            control={control}
        />
        {["rfi","rfp","lpu"].includes(comercialStage) && 
        <label>
            <b>Anexar especificação do cliente</b>
        <input type="file" id="spec" ref={register} name="client_specification"/>
        </label>
        }
        <Controller
            as={
                <Form.Field
                    label={<Fragment><b>Expectativa de Implantação</b> <Hint text="Previsibilidade aproximada de implantação do projeto." /></Fragment>}
                    control='select'>
                    <option value='6'>Em 6 meses</option>
                    <option value='12'>Em um ano</option>
                    <option value='24'>Mais de um ano</option>
                    <option value='0'>Sem previsão</option>
                </Form.Field>
            }
            name="expectation"
            defaultValue="6"
            control={control}
        />
    </Fragment>
)}
import React, { Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../utils/updateAction";
import { Form, Button } from "semantic-ui-react";
import { Hint } from "..";
import { Persona } from "./Persona";

interface IFormInput {
    client: string;
    state: any;
    contact: string;
    email: string;
    contact_email: string;
}

const MetaOportunityForm = (props: any) => {
    const { action, state } = useStateMachine(updateAction);
    const { watch, handleSubmit, control, errors, formState, setValue, register } = useForm<IFormInput>({ 
        mode: "onChange" ,
        defaultValues: {
            client: state.data.client,
            state: state.data.state || "RJ",
            contact: state.data.contact,
            email: state.data.email,
            contact_email: state.data.contact_email
          }
    });
    const clientChoice = watch('client', state.data.client);
    const stateChoice = watch('state', state.data.state);
    const contactChoice = watch('contact', state.data.contact);
    const emailChoice = watch('contact_email', state.data.email);
    const extra = (clientChoice?.length && stateChoice?.length 
    && contactChoice?.length && emailChoice?.length) > 0 && !errors.contact_email;
    const onSubmit = (data: any) => {
        let newData;
        if(data.client_specification?.length > 0){
            const client_specification = readData(data.client_specification)[0];
            newData = {...data, client_specification, spect_name: client_specification.name}
        } else newData = data;
        action(newData);
        window.scroll(0, 0)
        props.history.push({
            pathname: "/proposal/solution",
            search: props.location.search,
        });
    };      

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                as={<Form.Input
                    label={<Fragment><b>Cliente</b> <Hint text="Nome da empresa cliente" /></Fragment>}
                    error={errors.client && { content: "Informe o cliente", pointing: "below" }}
                />}
                name="client"
                control={control}
                rules={{ required: true }}
            />
            <Controller
                as={
                    <Form.Field
                        label={<Fragment><b>Estado da instalação</b> <Hint text="Estado onde será realizada a instalação" /></Fragment>}
                        control='select'>
                        <BRStates />
                    </Form.Field>
                }
                name="state"
                control={control}
            />
            <Controller
                as={<Form.Input
                    label={<Fragment><b>Contato</b> <Hint text="Contato principal dentro da empresa cliente." /></Fragment>}
                    error={errors.contact && { content: "Informe o contato", pointing: "below" }}
                />}
                name="contact"
                control={control}
                rules={{ required: true }}
            />
            <Controller
                as={<Form.Input
                    type="email"
                    label={<Fragment><b>Email</b> <Hint text="Email do contato" /></Fragment>}
                    error={errors.contact_email && { content: errors.contact_email.message, pointing: "below" }}
                />}
                name="contact_email"
                control={control}
                rules={{
                    required: true, 
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Informe um e-mail válido"
                    }
                }}
            />
            {extra && <Persona control={control} errors={errors} state={state} watch={watch} setValue={setValue} register={register} />}
            <Button type='submit' disabled={!formState.isValid} primary={formState.isValid}>Próximo</Button>
            {!formState.isValid && <span> Informações incompletas</span>}
        </Form>
    );
};

export default withRouter(MetaOportunityForm);


const BRStates = () => (
    <Fragment>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
    </Fragment>
)


const readData = (data: FileList) => {
    let myArray = []
    for(var i = 0; i < data.length; i++){
        let file = {
            'lastMod'    : data[i].lastModified,
           // 'lastModDate': data[i].lastModifiedDate,
            'name'       : data[i].name,
            'size'       : data[i].size,
            'type'			 : data[i].type,
        } 
        myArray.push(file)
    }
    return myArray;
}
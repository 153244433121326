import React, { Fragment } from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Controller } from 'react-hook-form'
import { useAuth0 } from '@auth0/auth0-react'
import { Phases } from './Phases'

export const ProjectSpec = ({ control, state }: any) => {
    const { user } = useAuth0()
    if(user["http://plataforma.phygitall.com.br/empresa"] !== "Phygitall")
        return null
    return (
    <Fragment>
        <Header as="h3">Especificações do projeto</Header>
        <Controller
            as={<Form.Input
                label={<Fragment><b>HH Analista de Negócios</b></Fragment>}
                type="number"
                />}
            name="hh_business"
            control={control}
            defaultValue={state.data.hh_hardware}
        />
        <Controller
            as={<Form.Input
                label={<Fragment><b>Descrição do escopo</b></Fragment>}
                type="text"
                />}
            name="scope_description"
            control={control}
            defaultValue={state.data.scope_description}
        />
        <Controller
            as={<Form.Input
                label={<Fragment><b>HH de Desenvolvimento de Software</b></Fragment>}
                type="number"
                />}
            name="hh_software"
            control={control}
            defaultValue={state.data.hh_software}
        />
        <Controller
            as={<Form.Input
                label={<Fragment><b>HH de Desenvolvimento de Hardware</b></Fragment>}
                type="number"
                />}
            name="hh_hardware"
            control={control}
            defaultValue={state.data.hh_hardware}
        />
        <Header as="h3">Tempo de Setup</Header>
        <Phases control={control} state={state} />
    </Fragment>
)}
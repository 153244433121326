import React, { Fragment, FunctionComponent } from 'react';
import { Form } from 'semantic-ui-react'
import { Controller } from "react-hook-form";

type ConectivityProps = {
    control: any;
}
const Conectivity: FunctionComponent<ConectivityProps> = ({ control }) => (
    <Fragment>
        <Form.Field label="Conectividade" control='select'>
            <option value='lora'>Lora</option>
            <option value='wifi'>Wifi</option>
        </Form.Field>
        <Controller
            as={<Form.Field label="Protocolo" control='select'>
                <option value='USB UART Serial'>USB UART Serial</option>
                <option value='4-20mA'>4-20mA</option>
            </Form.Field>}
            name="protocol"
            defaultValue="USB UART Serial"
            control={control}
        />
        <Controller
            as={<Form.Field label="Alimentação" control='select'>
                <option value='5'>5Vcc</option>
                <option value='24'>24Vcc</option>
                <option value="127">127~220Vca</option>
            </Form.Field>}
            name="source"
            defaultValue="5"
            control={control}
        />

    </Fragment>
)

export default Conectivity;
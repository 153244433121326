import axios from "axios";

export const sales = axios.create({
  baseURL: "https://7ayzurb3x4.execute-api.us-east-1.amazonaws.com",
});

export const fetchSales = async (params: any) => {
  const response = await sales.get("/leads", {
    params,
  });
  const res = await response.data;
  return res;
};

import React, { Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { withRouter, Link } from "react-router-dom";
import { Form, Button, Checkbox, List } from "semantic-ui-react";
import { Hint } from "..";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../utils/updateAction";

interface IFormInput {
    client: string;
    expenditure: string;
    paymentMethod: string;
    contract: number;
    format: string;
    attachment_institucional: string;
    attachment_solution: string;
    attachment_tech: string;
}

const PricingForm = (props: any) => {
    const { action, state } = useStateMachine(updateAction);
    const returnPath = {
        pathname: "/proposal/solution",
        search: props.location.search,
    }
    const { handleSubmit, control, errors, formState } = useForm<IFormInput>({
        mode: "onChange",
        defaultValues: {
            client: state.data.client,
            expenditure: state.data.expenditure || 'CAPEX',
            paymentMethod: state.data.paymentMethod || 'Anual',
            contract: state.data.contract || 24,
            format: state.data.format || 'Resumido',
            attachment_institucional: state.data.attachment_institucional,
            attachment_solution: state.data.attachment_solution,
            attachment_tech: state.data.attachment_tech
        }
    });

    const onSubmit = (data: any) => {
        action(data);
        window.scroll(0, 0)
        props.history.push({
            pathname: "/proposal/review",
            search: props.location.search,
        });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                as={<Form.Field
                    label={<Fragment><b>Modalidade</b> <Hint text={<Fragment><p> CAPEX: Aquisição de HW PHYGITALL + SW PHYGITALL SaaS</p>
                        <p>OPEX: Disponibilização em Comodato HW PHYGITALL + SW PHYGITALL SaaS</p></Fragment>} /></Fragment>}
                    control='select'>
                    <option value='CAPEX'>CAPEX</option>
                    <option value='OPEX'>OPEX</option>
                </Form.Field>}
                name="expenditure"
                control={control}
            />
            <Controller
                as={<Form.Field
                    label={<Fragment><b>Forma de Pagamento</b> <Hint text="Forma/Condição de Pagamento" /></Fragment>}
                    error={errors.paymentMethod && { content: "Informe o cliente", pointing: "below" }}
                    control='select'>
                    <option value='Mensal'>Mensal</option>
                    <option value='Semestral'>Semestral</option>
                    <option value='Anual'>Anual</option>
                </Form.Field>}
                name="paymentMethod"
                control={control}
            />
            <Controller
                as={<Form.Field
                    label={<Fragment><b>Contrato</b> <Hint text="Tempo do contrato" /></Fragment>}
                    control='select'>
                    <option value='12'>12 meses</option>
                    <option value='24'>24 meses</option>
                    <option value='36'>36 meses</option>
                    <option value='48'>48 meses</option>
                </Form.Field>}
                name="contract"
                control={control}
            />
            <Controller
                as={<Form.Field
                    label={<Fragment><b>Formato</b> <Hint text="A versão completa inclui o detalhamento sobre todos os serviços." /></Fragment>}
                    control='select'>
                    <option value='Resumido'>Resumida</option>
                    <option value='Completo'>Completa</option>
                </Form.Field>}
                name="format"
                control={control}
            />
            <Fragment><b>Anexar documentos</b> <Hint text="Inserir documentos abaixo no e-mail" /></Fragment>
            <List>
                <List.Item>
                    <Controller
                        control={control}
                        name="attachment_institucional"
                        render={({ onChange, onBlur, value }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v.checked)}
                                label='Apresentação Institucional Phygitall'
                                checked={value}
                            />
                        )}
                    />
                </List.Item>
                <List.Item>
                <Controller
                        control={control}
                        name="attachment_solution"
                        render={({ onChange, onBlur, value }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v.checked)}
                                label='Apresentação da Solução'
                                checked={value}
                            />
                        )}
                    />
                </List.Item>
                <List.Item>
                <Controller
                        control={control}
                        name="attachment_tech"
                        render={({ onChange, onBlur, value }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v.checked)}
                                label='Documentos Técnicos'
                                checked={value}
                            />
                        )}
                    />
                </List.Item>
            </List>
            <Button as={Link} secondary content='Retornar' to={returnPath} />
            <Button type='submit' disabled={!formState.isValid} primary={formState.isValid}>Próximo</Button>
            {!formState.isValid && <span> Informações incompletas</span>}
        </Form>
    );
};

export default withRouter(PricingForm);

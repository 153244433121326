import React from 'react'
import {
    Container,
    Header,
} from 'semantic-ui-react';
import Form from '../components/Form/MetaOportunityForm';
import { ProposalStep } from '../components'

export const MetaOpportunity = () => {
    return (
        <Container text style={{ marginTop: '7em', minHeight: '360px' }}>
            <ProposalStep current="" />
            <Header as='h1'>Cadastro de Oportunidade</Header>
            <p>Por favor insira os detalhes abaixo</p>
            <Form />
        </Container>
    )
}
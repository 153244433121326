import React, { Fragment, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller } from "react-hook-form";
import { Hint } from '..';



const Model = ({ control, targetChoice, setValue, state }: any) => {
    useEffect(()=> {
        if(!state.data.use){
            if(targetChoice === 'Pessoas'){
                setValue("use", "Individual")
            } else setValue("use", "Grande")
        }
    },[setValue, targetChoice, state])
    return (
    <Fragment>
        <Controller
            as={
            <Form.Field 
                label={<Fragment><b>Ambiente</b> <Hint text={<Fragment><p>Utilização em ambiente Interno (Indoor)</p>
                <p>Utilização em ambientes Externos e Internos (Outdoor + Indoor)</p></Fragment>} /></Fragment>}
                control='select'>
                <option value='Indoor'>Indoor</option>
                <option value='Outdoor'>Outdoor + Indoor</option>
            </Form.Field>}
            name="environment"
            control={control}
        />
        <Controller
            as={<Form.Field 
                label={<Fragment><b>Alvo</b> <Hint text={<Fragment><p>Ativos: Instrumentos/equipamentos/veículos</p>
                    <p>Pessoas: Colaboradores/Visitantes</p></Fragment>} /></Fragment>}
                control='select'>
                <option value='Ativos'>Ativos</option>
                <option value='Pessoas'>Pessoas</option>
            </Form.Field>}
            name="target"
            control={control}
        />
        {targetChoice === "Pessoas" ?
            <Controller
                as={<Form.Field label={<Fragment><b>Uso</b> <Hint text={<Fragment><p>Individual: Uso do wearable individualizado (ideal para condições em que se conhece o usuário do wearable ou que há cadastro manual correlacionando pessoa ao wearable)</p>
                    <p>Coletivo: Uso do wearable coletivo (ideal para condições onde o mesmo wearable pode ser utilizado por mais de um usuário ao longo do tempo, esta versão possui leitor NFC para identificação de cartão crachá  NFC)</p></Fragment>} /></Fragment>}
                control='select'>
                    <option value='Individual'>Individual</option>
                    <option value='Coletivo'>Coletivo</option>
                </Form.Field>}
                name="use"
                control={control}
            /> :
            <Controller
                as={<Form.Field label={<Fragment><b>Tamanho</b> <Hint text={<Fragment><p>Grande: Ativos de grande dimensão (Ex: acima de 1 mt comprimento por 1 mt de altura)</p>
                    <p>Médio: Ativo de média dimensão (Ex: abaixo de 1 mt comprimento e de 1 mt de altura)</p>
                    <p>Pequeno: Ativos de pequena dimensão (Ex: podem ser carregadas com a mão)</p></Fragment>} /></Fragment>}
                control='select'>
                    <option value='Grande'>Ativos de Grandes Dimensões</option>
                    <option value='Pequeno'>Ativos de Pequenas Dimensões</option>
                </Form.Field>}
                name="use"
                control={control}
            />
        }
        
    </Fragment>
)}
export default Model;
import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Navbar, Content, Footer, LoginWarning } from "./components";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect /* Redirect */,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PrivateRoute from "./components/PrivateRoutes";
import { MetaOpportunity, Pricing, Review, ListProposal, Leads } from "./views";
import { StateMachineProvider, createStore } from "little-state-machine";
import { Loader } from "semantic-ui-react";
import { ToastProvider } from "react-toast-notifications";

createStore({
  data: {},
});

function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loader active inline="centered" />;
  }
  return (
    <ToastProvider autoDismiss autoDismissTimeout={3000} placement="top-center">
      <StateMachineProvider>
        <Router>
          <Navbar />
          {!isAuthenticated && <LoginWarning />}
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/proposal" />} />
            <PrivateRoute exact path="/leads" component={Leads} />
            <PrivateRoute
              exact
              path="/proposal/opportunity"
              component={MetaOpportunity}
            />
            <PrivateRoute exact path="/proposal/solution" component={Content} />
            <PrivateRoute exact path="/proposal" component={ListProposal} />
            <PrivateRoute exact path="/proposal/pricing" component={Pricing} />
            <PrivateRoute exact path="/proposal/review" component={Review} />
            <Route
              render={() => (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  404 - Não encontrado
                  <br />
                  <br />
                  <br />
                </div>
              )}
            />
          </Switch>
        </Router>
      </StateMachineProvider>
    </ToastProvider>
  );
}

export default App;

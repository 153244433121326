import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from './serviceWorker';
import history from "./utils/history";


const onRedirectCallback = (appState: any) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname);
  };

ReactDOM.render(
    <React.StrictMode>
            <Auth0Provider
                domain="phygitall.auth0.com"
                clientId="SwxLl6E0FyhOPZ3qb8Tdp0JAdMCf98j4"
                audience="https://phygitall.auth0.com/userinfo"
                scope="openid profile email"
                redirectUri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
                    <App />
            </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Header } from 'semantic-ui-react'
import { useForm, Controller } from "react-hook-form";
import { Hint } from '../../components'
import Model from './Model';
import Conectivity from './Conectivity';
import updateAction from "../../utils/updateAction";
import { useStateMachine } from 'little-state-machine';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { ProjectSpec } from './ProjectSpec';
import { useAuth0 } from '@auth0/auth0-react';

enum ModelEnum {
    track = "track",
    telemetry = "telemetry",
}

interface IFormInput {
    hardware_supplier: string;
    name: string;
    environment: string;
    target: string;
    product: string;
    use: string;
    model: ModelEnum;
    client: string;
    state: any;
    quantity: number;
    potential_quantity: number;
    quantity_spare: number;
}

export const ProposalForm = () => {
    const { action, state } = useStateMachine(updateAction);
    const { user } = useAuth0()
    const { watch, handleSubmit, control, errors, setValue, formState, trigger } = useForm<IFormInput>({
        mode: "onChange",
        defaultValues: {
            hardware_supplier: state.data.hardware_supplier || "Phygitall",
            name: state.data.model || "track",
            environment: state.data.environment || "Indoor",
            target: state.data.target || "Ativos",
            use: state.data.use || "Grande",
            product: state.data.product,
            potential_quantity: state.data.potential_quantity || 0,
            quantity: state.data.quantity || 100,
            quantity_spare: state.data.quantity_spare
          }
    });
    const { isValid } = formState;
    const [products, setProducts] = useState<string[]>([])
    const history = useHistory()
    const location = useLocation();
    const modelChoice = watch('model', 'track');
    const targetChoice = watch('target', "Ativos");
    const useChoice = watch('use', 'grande');
    const qt = watch('quantity');
    const shouldSee = ["Logicalis", "Phygitall"].includes(user["http://plataforma.phygitall.com.br/empresa"]);
    const isAdmin = user["http://plataforma.phygitall.com.br/empresa"] === "Phygitall";
    const returnPath = {
        pathname: "/proposal/opportunity",
        search: location.search,
    }
    useEffect(() => {
        if(qt){
            const newQtS = Math.floor(qt  * 0.05)
            setValue('quantity_spare', newQtS)
        }
    },[qt, setValue])
    const onSubmit = (data: any) => {
        action(data);
        window.scroll(0, 0)
        history.push({
            pathname: "/proposal/pricing",
            search: location.search,
        });
      };
    useEffect(() => {
        if (modelChoice === 'telemetry')
            setProducts(["PHYLL Connect"])
        else if (targetChoice === "Pessoas") {
            if (useChoice === "Individual")
                setProducts(["PHYLL Smartbadge", "PHYLL Smartband"])
            else
            setProducts(["PHYLL Smartbadge"])
        } else setProducts(["PHYLL Tracker"])
        trigger()
    }, [modelChoice, targetChoice, useChoice, trigger])
    useEffect(() => {products.length && setValue("product", products[0])}, [setValue, products])
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header as='h3'>Mapeando a Oportunidade</Header>
            {shouldSee && <Controller
                as={
                    <Form.Field 
                        label={<Fragment><b>Fornecimento do Hardware</b> <Hint text="Informar se o hardware é PHYGITALL ou de outros." /></Fragment>}
                        control='select'>
                        <option value='Phygitall'>Phygitall</option>
                        <option value='Terceiros'>Terceiros</option>
                    </Form.Field>
                }
                name="hardware_supplier"
                control={control}
            />}
            {isAdmin && <Controller
                as={<Form.Field
                    label="Arquitetura de Software"
                    control='select'>
                    <option value='Cloud'>Cloud</option>
                    <option value='Híbrida'>Híbrida</option>
                </Form.Field>}
                name="software_architecture"
                control={control}
                defaultValue={state.data.software_architecture}
            />}
            <Controller
                as={
                    <Form.Field 
                        label={<Fragment><b>Aplicação</b> <Hint text={<Fragment><p>Rastreamento: Localização (pessoas e ativos) + Sensores.                            </p>
                        <p>Telemetria: Envio de dados de instrumentos/equipamentos dos cliente</p></Fragment>} /></Fragment>}
                        control='select'>
                        <option value='track'>Rastreamento</option>
                        <option disabled value='telemetry'>Telemetria</option>
                    </Form.Field>
                }
                name="model"
                control={control}
            />
            {modelChoice === "track" ? <Model control={control} targetChoice={targetChoice} setValue={setValue} useChoice={useChoice} state={state}/>
                : modelChoice === "telemetry" ? <Conectivity control={control} />
                    : null
            }
            <Controller
                as={<Form.Field 
                    label={<Fragment><b>Produto</b> <Hint text={<Fragment><p>PHYLL Tracker: Rastreador de Ativos</p>
                     <p>PHYLL Smartbadge: Wearable em formato crachá </p>
                    <p>PHYLL Smartband: Wearable em formato pulseira</p></Fragment>} /></Fragment>}
                    control='select'>
                    <Fragment>
                        {products.map(product =>
                            <option key={product} value={product}>{product}</option>
                        )}
                    </Fragment>
                </Form.Field>}
                name="product"
                control={control}
            />
            <Controller
                as={<Form.Input
                    label={<Fragment><b>Quantidade Inicial para Cotação</b> <Hint text="Inserir quantidade de dispositivos" /></Fragment>}
                    type="number"
                    error={errors.quantity && { content: "Informe a quantidade de produto", pointing: "below" }}
                />}
                name="quantity"
                key="quantity"
                control={control}
                rules={{ required: true }}
            />
            <Controller
                as={<Form.Input
                    label={<Fragment><b>Quantidade Potencial</b> 
                    <Hint text={<Fragment><p>Estimativa da quantidade total de produtos que a oportunidade pode abranger.</p>
                        <p>Se não souber, lance 0</p></Fragment>} /> </Fragment>}
                    type="number"
                />}
                name="potential_quantity"
                control={control}
            />
            <Controller
                as={<Form.Input
                    label={<Fragment><b>Quantidade Spare Part</b> 
                    <Hint text="Quantidade de dispositivos recomendada para troca imediata" /></Fragment>}type="number"
                />}
                name="quantity_spare"
                key="quantity_spare"
                control={control}
            />
                <ProjectSpec control={control} state={state} />
                <Button as={ Link } secondary content='Retornar' to={returnPath}/>
                <Button type='submit' disabled={!isValid} primary={isValid} content='Próximo'  />
                {!isValid && <span> Informações incompletas</span>}
        </Form>
    )
}

export default ProposalForm;
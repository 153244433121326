import React from 'react';
import { Icon, Popup, SemanticShorthandItem, PopupContentProps } from 'semantic-ui-react';

export const Hint = ({text}: {text: SemanticShorthandItem<PopupContentProps>}) => (
    <Popup 
        content={text}
        hideOnScroll
        position='bottom center'
        trigger={<Icon name='help circle'/>}
    />
    
)